<template>
  <div>
    <router-link v-if="isClickable" class="card-link"
                 :to="`/browse/music/album/${album.mobile_album_id}`">
      <!-- Thumbnail -->
      <img class="card-carousel-card-thumbnail"
           :src="album.album_thumbnail"
           :style="{
             width  : cardWidthStyle + 'px',
             height : cardHeightStyle + 'px' }"
           alt="album-thumbnail" />

      <!-- Title -->
      <div class="card-carousel-card-footer">
        <p class="title mb-0"> {{ album.album_name | truncate }} </p>
        <p class="sub-title mb-0"> {{ album.no_of_tracks }}
          track<span v-if="album.no_of_tracks > 1">s</span>
        </p>
      </div>
    </router-link>
    <div v-else class="overlay">
            <!-- Thumbnail -->
      <img class="card-carousel-card-thumbnail"
           :src="album.album_thumbnail"
           :style="{
             width  : cardWidthStyle + 'px',
             height : cardHeightStyle + 'px' }"
           alt="album-thumbnail" />

      <!-- Title -->
      <div class="card-carousel-card-footer">
        <p class="title mb-0"> {{ album.album_name | truncate }} </p>
        <p class="sub-title mb-0"> {{ album.no_of_tracks }}
          track<span v-if="album.no_of_tracks > 1">s</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import FilterMixin from '@/mixins/FilterMixin';

  export default {
    name   : 'AlbumCarouselCard',
    mixins : [
      FilterMixin,
    ],
    props : {
      album             : Object,
      columnItems       : Number,
      cardCarouselWidth : Number,
    },
    data(){
      return {
        planId : this.$store.getters['subscription/subscription'].plan_id,
      }
    },
    computed : {
      cardWidthStyle() {
        const gap = 18; // left and right margin 9px ea
        return (this.cardCarouselWidth / this.columnItems) - gap;
      },
      cardHeightStyle() {
        return this.cardWidthStyle ? this.cardWidthStyle : 0;
      },
      isClickable : function(){
        if(this.planId > 1)
          return true;
        else if(this.album.has_free)
          return true;

        return false;
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/user/category/album-carousel-card";
</style>