import { render, staticRenderFns } from "./AlbumCarouselCard.vue?vue&type=template&id=5502f0e6&scoped=true"
import script from "./AlbumCarouselCard.vue?vue&type=script&lang=js"
export * from "./AlbumCarouselCard.vue?vue&type=script&lang=js"
import style0 from "./AlbumCarouselCard.vue?vue&type=style&index=0&id=5502f0e6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5502f0e6",
  null
  
)

export default component.exports